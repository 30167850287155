.swal-text{
    text-align: center;
}

.swal-title{
    font-size: 18px;
}
.swal-button {
    background-color: #fff;
    border: 1px solid #123FBC;
    color: #123FBC;
  }
  
  .swal-button--cancel {
    background-color: #fff;
    border: 1px solid #123FBC;
    color: #123FBC;
  }
  
  .swal-button--confirm {
    background-color: #123FBC;
    color: #ffffff;
  }
  
  .swal-button--confirm:not([disabled]):hover{
      background: #123FBC;
      opacity: 0.5;
  }