.header-reset{
  height: 61px;
  background: #123FBC;
  box-shadow: -1px -1px 30px -3px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  color:white;
  font-weight: bold;
  line-height: 61px;
  text-align: center;
  img{
    position: absolute;
    left:140px;
  }
}

.retriggered-static{
  height: 100vh;
}

.reset-box{
  width:55%;
  margin-top:10%;
  margin-left:20%;
  display: flex;
  justify-content: space-between;
  .reset-second-box{
    text-align: right;
    h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 128%;
    color: #3D3D3D;
    letter-spacing: 2px;
    }
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 159%;
      margin-top: 20px;
      letter-spacing: 0.025em;
      color: #3D3D3D;
      span{
        font-weight: bold;
      }
    }
    button{
      width: 260px;
      height: 54px;
      border: 1px solid #123FBC;
      box-sizing: border-box;
      border-radius: 37px;
      color:#123FBC;
      font-weight: bold;
      background-color: white;
      cursor: pointer;
      margin-top:20px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .reset-box {
    margin-left:10%;
    width:80%;
    margin-top:15%;
  }
}

@media screen and (max-width: 850px) {
  .reset-box {
    margin-left:10%;
    width:80%;
    margin-top:15%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset-second-box{
    margin-top:50px;
  }
  .header-reset{
    img {
      left:20px;
    }
  }
}