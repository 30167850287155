.dashboard {

    .left-head {
        font-weight: 800;
        font-size: 18px;
    }

    .right-head {
        font-weight: 800;
        font-size: 13px;
        padding: 25px;
    }

    .table td, .table th {
        padding: .75rem;
        vertical-align: top;
        border-top: none;
    }

    .dashboard-table {
        height: 446px;
        overflow-y: auto;
        width: 98%;
        margin: 20px;
    }

    .table th {
        background: #fff;
        border-bottom: none;
        color: #123FBC;
    }

    table th:first-child{
        border-radius:10px 0 0 10px;
        // text-align: center;
    }

    table th:last-child{
        border-radius:0 10px 10px 0;
        // text-align: center;
    }

    table tr {
        text-align: center;
    }

    table {
        width: 96%;
    }

    .dashboard-slider {
        height: 530px;
        .statistics {
            font-size: 22px;
        }
        .carousel-status {
            display: none;
        }
        .position-number{
            font-size: 15px;
            border-bottom: 1px solid;
            padding-bottom: 40px;
        }
        .interview-text {
            display: flow-root;
        }
        .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
        }
        .swiper-container{
            border-top: 0.4px solid #123FBC;;
        }
        .bottom-area {
            padding-top: 20px;
            padding-bottom: 30px;
            margin: auto;
            margin-right: 20px;
        }
        .carousel .slide {
            background: #fff;
        }
        .carousel.carousel-slider .control-arrow:hover {
            background: #fff;
        }
        .carousel.carousel-slider .control-arrow {
            display: none;
        }
        .carousel .slider-wrapper {
            margin-bottom: 20px;
        }
        .carousel .control-dots {
            padding: 0px 45px;
            .dot {
                background: #123FBC;
                box-shadow: none;
                outline: none;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                display: inline-block;
                .selected {
                    background: #123FBC;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
}
