@import './variable.scss';


::-webkit-scrollbar {
    width: 10px;
  }

::-webkit-scrollbar-track {
    background-color: transparent;
  }

::-webkit-scrollbar-thumb {
    background: #BFBFBF;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-radius: 10px;
  }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #a8a2a2;
}

.bg-primary-new{
    background-color: #123FBC !important;
}
.c-white{
    color: white !important;
}
.alignleft {
	float: left;
}
.opacity-3{
    opacity: 0.3;
}
.opc-3-point-evnt{
    opacity: 0.3;
    pointer-events: none;
}
.dsp-none{
    display: none;
}
.hide{
    display: none;
    pointer-events: none;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}

.alignright {
	float: right;
}

.ml-30{
    margin-left: 30px;
}
.fl-b-30{
    flex-basis: 30%;
}
.fl-b-70{
    flex-basis: 70%;
}

.fl-b-100{
    flex-basis: 100%;
}
.fl-sp-bw{
    justify-content: space-between;
}
.fl-item-cnt{
    align-items: center;
}
.fl-centre{
    justify-content: center;
}
.m-rht-lft-31{
    margin-left: 31px;
    margin-right: 31px;
}
.m-rht-lft-10{
    margin-left: 10px;
    margin-right: 10px;
}
.m-top-btm-16{
    margin-top: 16px;
    margin-bottom: 16px;
}

.p-top-btm-16{
    padding-top: 16px;
    padding-bottom: 16px;

}

.bor-botm{
    border-bottom: 1px solid #E4E4E4;
}
.ml-auto{
    margin-left: auto;
}

.ml-35{
    margin-left: 35px;
}
.ml-40{
    margin-left: 40px;
}
.ml-90{
    margin-left: 90px;
}
.mr-16{
    margin-left: 16px;
}
.p-rght-40{
    padding-right: 40px;
}
.p-20 {
    padding: 20px;
}
.p-40 {
    padding: 40px;
}
.ml-20{
    margin-left: 20px;
}
.pl-0 {
    padding-left: 0px;
}
.pb-40 {
    padding-bottom: 50px;
}
.p-0 {
    padding: 0px;
}
.pt-6 {
    padding-top: 6px;
}
.pt-10 {
    padding-top: 10px;
}
.pb-15 {
    padding-bottom: 15px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pt-30 {
    padding-top: 30px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-5 {
    padding-right: 5px;
}
.pl-22{
    padding-left: 22px;
}
.p-60 {
    padding: 60px;
}

.p-90 {
    padding: 90px;
}

.pl-30 {
    padding-left: 30px;
}
.pull-right {
    float: right;
}

.w-100 {
    width: 100%;
}
.w-14{
    width: 10px;
}
.rotate-180{
    transform: rotate(180deg);
}

.h-100 {
    height: 100%;
}

.pull-left {
    float: left;
}

.f-white {
    color: #fff !important;
}

.fc-blue {
    color: #123FBC;
}

.pt-40 {
    padding-top: 40px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-30 {
    margin-bottom: 30px;
}

.mrl-10{
    margin: 0px 10px 0px 10px
}

.pl-40{
    padding-left: 40px;
}
.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}
.pl-30 {
    padding-left: 30px;
    font-weight: bold;
}
.pl-10{
    padding-left: 10px;
}
.pr-10{
    padding-right: 10px;
}
.pl-50 {
    padding-left: 50px;
}
.fw-900 {
    font-weight: 900;
}

.fw-700 {
    font-weight: 700;
}

.fw-500 {
    font-weight: 500;
}
.fw-400 {
    font-weight: 400;
}
.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-18 {
    font-size: 18px;
}

.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-14{
    font-size: 14px;
}

.bg-white {
    background-color: #fff;
}

.mt-40 {
    margin-top: 40px;
}

.mt-20 {
    margin-top: 20px;
}
.font-weight-600{
    font-weight: 600;
}

.ml-10{
    margin-left: 10px !important;
}

.mr-10{
    margin-right: 10px !important;
}
.ml-50{
  margin-left: 50px;
}
.dsp-flex{
    display: flex;
}
.space-between{
    justify-content: space-between;
}
.mb-0{
    margin-bottom: 0;
}
.p-0{
    padding: 0;
}
.m-0{
    margin: 0;
}

.btn-outline-primary:hover {
    color: #123FBC !important;
    background: none !important;
    border-color: #366BFF;
}

.iv-header {
    .onhover-div {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
        font-family: Lato,sans-serif;
    }

    .sticky.fixed .onhover-div .show-div {
        top: 66px;
    }

    .onhover-div .show-div ul li {
        display: block;
        padding: 10px;
    }
    .show-div {
        display: none;
    }
    .mobile-setting:hover {
        .show-div {
            display: block;
        }
    }

}
.sidebar-container {
    position: sticky;
    top: 0px;
}

.border-bottom {
    border-bottom: 0.4px solid #3d3d3d;
}
.custom-link {
    color: #123FBC;
    cursor: pointer;
    .link-icon{
        margin-right: 5px;
    }
    .link-text{
        text-decoration: underline;
        font-size: 14px;
    }
}
.td-underline {
    text-decoration: underline;
}
.custom-bottom {
    position: absolute;
    width: 93%;
}

.react-tel-input .form-control {
    width: 100% !important;
    border: 0 !important;
    border-radius: 4px !important;
    height: 30px !important;
    background: white !important;
}

.passed{
    color: #12BC93;
    font-size: 13px;
    font-weight: 600;
}

.rejected{
    color: red;
    font-size: 13px;
    font-weight: 600;
}
.emptyresult{
    color:#3D3D3D;
    // font-size: 14px;
    font-weight: 600;
}
.err-msg {
    color: red;
    text-align: center;
}
.center{
    text-align: center;
}
.chand{
    height: 37px;
}
.normalBtn {
    background-color:#123FBC;
}
.whiteBtn {
    background-color: white;
}
.inactive-tab-count-bg {
    background-color: #EDEDED;
}
.normalText {
  color:white;
}
.blackText {
    color:black;
}
.click-none{
    pointer-events: none;
}
.ai-center{
    align-items:center;
};
.overflow-scroll{
    // overflow: scroll;
}
.overflow-hidden{
    overflow: hidden;
}
.mx-h-650{
    max-height: 680px;
}

.green{
    color: $green !important;
}
.red{
    color: $red !important;
}
.black{
    color: $black !important;
}
.blue{
    color: $blue !important;
}
.lightGray{
    color: $lightGray !important;
}

// for input
.mb-25 {
    margin-bottom: 25px;
}
.mb-15{
    margin-bottom: 15px;
}
.h-30{
    height: 30px;
}
.ml-15{
    margin-left: 15px;
}
.w-20 {
    width: 20%;
}
.mb-19 {
    margin-bottom: 19px;
}

.w-40 {
    width: 50%;
}
.w-363{
    width: 363px;
}
.w-323{
    width: 323px;
}
.w-275{
    width: 275px !important;
}

.w-289 {
    width: 289px;
}
.ml-25{
    margin-left: 25px;
}
.ml-53{
    margin-left: 53px;
}
.mr-20{
    margin-right: 20px;
}
.mr-23{
    margin-right: 23px;
}
.mr-27{
    margin-right: 27px;
}
.mt-46{
    margin-top: 46px;
}
.mt-30{
    margin-top: 30px;
}
.mr-30{
    margin-right: 30px;
}
.mb-21{
    margin-bottom: 21px;
}
.mb-14{
    margin-bottom: 14px;
}
.ml-28{
    margin-left: 28px;
}
.common-dropdown-border {
    border: 1px solid #C9C9C9;
    border-radius: 4px;
}
.label-common {
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
    width: 165px;
}
.common-label{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
}


.common-input {
    width: 416px;
    height: 30px;
}
.custom-cc{
    height: 85px;
    overflow: scroll;
    font-size: 8px;
    max-width: 50%;
 }
 .custom-comment{
    min-height: 85px;
    overflow: scroll;
    font-size: 8px;
    max-width: 50%;
 }
 .custom-input-field{
    font-family: Poppins;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
    height: 30px;
    padding: 10px;
 }

 .w-20 {
    width: 20%;
}
.w-50 {
    width: 50%;
}


.mb-25 {
    margin-bottom: 25px;
}

.h-30 {
    height: 30px;
}

.ml-15 {
    margin-left: 15px;
}

.mb-19 {
    margin-bottom: 19px;
}

.mb-10 {
    margin-bottom: 10px;
}
.w-289 {
    width: 289px;
}

.w-200{
    width: 200px;
}
.w-165{
    width: 165px;
}
.w-100p{
    width: 100px;
}
.MuiButton-containedPrimary{
    background-color: #123FBC !important;
}
.w-80-percent{
    width: 80%;
}

.text-decoration-none{
    text-decoration: none;
}

.ql-editor {
    padding: 2px;
    overflow-y: unset;
}
