.interview-created-main-wrapper {
    .interview-body-box{
      display: flex;
      justify-content: center;
    }
    //navbar......
    .navbar-wrapper{
        width: 100%;
        height: 61px;
        background-color:  #123FBC;
        position: relative;

        .center-text{
            position: absolute;
            left: 43%;
            .heading-text{
                color: white;
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 18px;
                line-height: 0%;
                letter-spacing: 0.095em;
                text-transform: uppercase;
     
            }
        } 
        .logoImg{
            margin-top: 7px;
        }
    }
     

    //body...
    .interview-heading{
        position: absolute;
        width: 668px;
        height: 61px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 128%;
        color: #3D3D3D; 
    }

    .interview-body{
        text-align: right;
    }

    .interview-text{

        font-family: 'Poppins';
        font-style: normal;
        font-size: 17px;
        line-height: 176%;
        letter-spacing: 0.025em;

        .interview-text-span{
            font-weight: 600;
        }
    }

    .button{
        margin-top: 30px;
        margin-left: 280px;
       
    }
     .button button{
        border-radius:  37px;
        width: 160px;
        height: 40px; 
        background-color: white;
        border: 1px solid #123FBC;
        color: #123FBC;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 0%;
        text-align: center;
        letter-spacing: 0.025em;
        text-transform: uppercase;
     }

}