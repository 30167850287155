body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F8F8F8;
    color: #3D3D3D;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  * {
    box-sizing: border-box;
  }
  .activeTab{
    // border-bottom:4px solid #123FBC;
    padding-bottom: 10px;
    color:#123FBC;
  }
   .activeTab1{
    border-bottom:2px solid #42b3f4;
    padding-bottom: 10px;
    color:blue
  }
  .btn{
   @media(max-width:600px){
    margin-top: 300px;
   }
  }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

  }
  .inputfile + label {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background: #123FBC;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 146px;
}

.inputfile:focus + label,

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.proceed {
  color:#12BC93
}

.cancelled {
  color:#FC1F1F;
}

.yellow {
  color:#D2691E;
}

$color1: #f4f4f4;
$color2: #123FBC;

.radio {
  margin: 0.3rem;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {

      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        position: relative;
        top: -0.0em;
        margin-right: 0.6em;
        margin-top: 2px;
        vertical-align: top;
        cursor: pointer;
        text-align: left;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;

        }
      }
    }
  }
}

.radio-label{
  font-size: 13px;
  color: #383838;
}

.Toastify__toast {
  min-height: 50px;
  padding: 2px;
  text-align: center;
}

.not-allowed{
  cursor: not-allowed;
}
