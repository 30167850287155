    .not-found {
        display: flex;
        justify-content: space-around;
        padding: 0 70px;

        .not-found-img {
            width: 311px;
            height: 321px;
            object-fit: contain;
        }

        .mt-80 {
            margin-top: 80px;
        }

        .went-wrong {
            font-family: Poppins;
            font-weight: bold;
            font-size: 52px;
            line-height: 119%;
            text-align: right;
            letter-spacing: 0.025em;
            color: #3D3D3D;
        }

        .sorry-text-container {
            margin-top: 40px;

            .sorry-txt {
                font-family: Poppins;
                font-size: 14px;
                text-align: right;
                letter-spacing: 0.025em;
                color: #3D3D3D;
            }
        }

        .reload-page {
            border: 1px solid #123FBC;
            border-radius: 50px;
            padding: 16px 60px;
            width: 262px;
            margin-top: 31px;
            text-align: right;
            font-family: Poppins;
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
            color: #123FBC;
        }
    }

    @media only screen and (max-width: 920px) {

        .not-found {
            .went-wrong-container {
                display: flex;
                justify-content: center;

                .went-wrong {
                    text-align: center;
                }

                .sorry-text-container {
                    .sorry-txt {
                        text-align: center;
                    }
                }
            }

            .not-found-img {
                margin-bottom: 71px;
            }

            .reload-btn {
                justify-content: center;
            }
        }
    }