.page-loading {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #b4b4b4;
    color: #b4b4b4;
    box-shadow: 9999px 0 0 -5px #b4b4b4;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #b4b4b4;
    color: #b4b4b4;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #b4b4b4;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #b4b4b4;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #b4b4b4;
    }
    30% {
        box-shadow: 9984px 0 0 2px #b4b4b4;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #b4b4b4;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #b4b4b4;
    }
    30% {
        box-shadow: 9999px 0 0 2px #b4b4b4;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #b4b4b4;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #b4b4b4;
    }
    30% {
        box-shadow: 10014px 0 0 2px #b4b4b4;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #b4b4b4;
    }
}
