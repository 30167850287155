.testCompleted-screen-parent{
    padding: 30px;

    .mt-30{
        margin-top: 30px;
    }

    .mt-10{
         margin-top:10px
    }

    .mt-50{
        margin-top: 50px;
    }

    .bold{
        font-weight: bold;
    }

    .fw-600 {
        font-weight: 600;
    }

    .mt-2{ 
        margin-top: 2%;
    }

    .mt-3{
        margin-top: 6%;
    }

    .mt-10{
        margin-top: 5%;
    }

    .bt-10{
        margin-bottom: 2%;
    }


     .close-btn{
        border: 1px solid #123FBC;
        border-radius: 37px;
        width: 260px;
        height: 54px;
        margin-top:50px;
        margin-left: 310px;
        margin-bottom: 50px;

        // test......
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            color: #123FBC;
     }

     .link-expired {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 112%; 
        text-align: center;
        color: #D01C1C;
        margin-top: -150px;
     }

     .client-details {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 112%; 
        text-align: center;
        color: #3D3D3D;
     }

     .email-sent-text {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 18px;
        line-height: 112%; 
        text-align: center;
        color: #3D3D3D;
     }

     .close-btn {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        line-height: 112%; 
        text-align: center;
        color: #123FBC;
        border: 1px solid #123FBC;
     }

     .ml-150 {
        margin-left: 150px;
     }

     .resend-btn {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        line-height: 112%; 
        text-align: center;
        width: 260px;
        height: 54px;
        border-radius: 50px;
        background-color: #123FBC;
        color: #F3F5FA;
        margin-left: 25px;
     }

     .resend-btn:hover {
        background-color: #123FBC;
        opacity: 0.8;
    }

     .test-over-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 112%;   
        text-align: center;
        // color: #123FBC;
     }

     .marginBottom-3 {
        margin-bottom: 3%;
     }

     .blue-color{
        color: #123FBC;
     }

     .black-colcor{
        color: #3D3D3D;
     }


     .text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;  
        text-align: center;
        color: #3D3D3D;
     }
}
.main-success-box {
    display: flex;
    gap: 20px;
    margin-top: 80px;
    justify-content: space-between;
    .box-1 {
        padding: 10px 22px;
    }
    .box-2 {
        padding: 32px 16px;
        width: 70%;
        height: 100%;
        border-radius: 14px;
        background: #FFF;
        box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
    }
    .experience-text-box {
        width: 96%;
        border-radius: 14px;
        background: #F3F5FA;
        padding: 10px 16px;
        text-align: center;
        .text  {
            color: #123FBC;
            font-size: 22px;
            font-family: "Poppins";
            font-weight: 500;
        }
    }
    .star {
        color: red;
    }
    .overall-text {
        font-family: "Poppins";
        font-size: 16px;
        text-align: center;
        color: #3D3D3D;
        font-weight: 400;
        .bold-600 {
            font-weight: 600;
        }
    }
    .rating-box {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .rating {
            cursor: pointer;
            width: 41px;
            height: 31px;
            border-radius: 8px;
            border: 1px solid #C0C0C0;
            display: flex;
            align-items: center;
            justify-content: center;   

            font-size: 16px;
            font-family: 'Poppins';
            font-weight: 600;
            color: #3D3D3D;
        }
        .selected-rating {
            border: none;
            background: #123FBC;
            color: white;
        }
    }
    .things-text {
        color: #3D3D3D;
        font-family: "Poppins";
        font-size: 16px;
        text-align: center;
    }
    .reason-text {
        border-radius: 28px;
        background: #F3F5FA;
        color: #666E86;
        font-family: "Poppins";
        font-size: 14px;
        padding: 6px 16px;
    }
    .selected-reason {
        background: #123FBC;
        color: #FFF;
    }
    .share-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 80%;
        .share-text {
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
        }
        .input {
            width: 100%;
            min-height: 80px;
            border-radius: 8px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
        }
    }
    .submit {
        background: #1844AD;
        color: white;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        padding: 8px 32px;
        border-radius: 6px;
    }
    .submit:hover {
        background: #1844AD;
        color: white;
    }
}