.loader-wrapper{
    background: rgba(26, 26, 26, 0.74);
    height: 100vh;
    width: 100vw;
    z-index: 99999999999999;
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.loader-wrapper > div[role="button"] {
    margin: unset !important;
}

.loader-text {
    color: #fff;
    font-weight: 600;
}