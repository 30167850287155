.candidate-name {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-align: left;
    white-space: nowrap;
    // width: 85%;
}

.interview-details{
    .multi-row{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .detail-text{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.025em;
    }
    .detail-key{
        vertical-align: top;
    }
    .detail-value-box{
        text-align: right;
    }
    .detail-value{
        color: #3D3D3D;
    }
    .sub-text{
        color: #9A9A9A;
        margin-top: 5px;
    }
}

.candidate-status {
    font-weight: bold;
    text-align: right;

    &.offered {
        color: #3db168;
    }

    &.dropped {
        color: #F40707;
    }
}

.candidate-status-subtext {
    text-align: right;
    color: #666;
}

.flow-root {
    display: flow-root;
}

.right-header {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    // font-weight: 600;
    // letter-spacing: 0.025em;
    text-align: left;
}
.side-drawer {
    overflow: auto;

    .table-striped{
        td{
            padding-left: 0px;
        }
    }
}

.align-right{
    text-align: right;
}

